import React, { useMemo } from 'react';
import BlockContent from '@sanity/block-content-to-react';
import Link from '../atoms/Link';
import { P1 } from '../atoms/typography';
import styled from '@emotion/styled';
import { path } from 'lodash/fp';
import Heading from '../../components/PageBlocks/Heading';
import { Box } from 'rebass';
import { PullQuoteBase } from '../../components/PageBlocks/PullQuote';
import { getRawText, interpolateTextBlock } from '~lib/util';
import { ImageBase } from '../../components/PageBlocks/Image';
import { DividerBase } from '../../components/PageBlocks/Divider';
import { css } from '@emotion/core';
import { VideoBase } from '../../components/PageBlocks/Video';
import PageBlockLink from '../../components/PageBlocks/Link';
import Table from '../../components/PageBlocks/Table';
import { TabsBase } from '../../components/PageBlocks/Tabs';
import { List, ListItem } from '~common/atoms/List';
import colors from '~lib/theme/rebrand/colors';

const BulletPoint = styled(Box)`
  height: 6px;
  width: 6px;
  min-height: 6px;
  min-width: 6px;
  max-height: 6px;
  max-width: 6px;
  background: ${props => props.theme.colors.primary};
`;

const StyledSpan = styled.span`
  display: ${props => (props.inline ? 'inline' : 'block')};
  color: ${props => props.color};
`;

const isEmptyBlock = props =>
  path('node._type')(props) === 'block' &&
  path('node.children.length')(props) === 1 &&
  path('node.children.0.text')(props) === '' &&
  path('node.children.0._type')(props) === 'span';

const blockMargins = props => css`
  //margin-top: ${props.theme.space[3]}px;
  margin-bottom: ${props.theme.space[3]}px;
`;

const TextBlock = styled(P1)`
  ${blockMargins};
  line-height: 1.5;
`;

const Superscript = styled.sup`
  color: ${props => props.color};
  vertical-align: super;
  font-size: ${props => props.theme.fontSizes[1]}px;
`;

const textColor = path('mark.textColor');

const RichTextImage = styled(ImageBase)`
  ${blockMargins}
`;

const RichTextDivider = styled(DividerBase)`
  ${blockMargins}
`;

const EmbedVideo = styled(VideoBase)`
  ${blockMargins};
`;

const Tabs = styled(TabsBase)`
  ${blockMargins};
`;

const serializers = {
  marks: {
    sup: props => {
      return (
        <Superscript
          className="superscript-block"
          inline={props._type === 'span'}
          color={textColor(props)}
        >
          {props.children}
        </Superscript>
      );
    },
    color: props => {
      return (
        <StyledSpan
          className="text-block"
          inline={props._type === 'span'}
          color={textColor(props)}
        >
          {props.children}
        </StyledSpan>
      );
    },
    link: props => {
      return (
        <Link
          className="link-block"
          style={{
            color: textColor(props),
          }}
          external
          to={props.mark.href}
        >
          {props.children}
        </Link>
      );
    },
    fileLink: props => {
      return (
        <Link
          className="link-block"
          style={{
            color: textColor(props),
          }}
          external
          to={
            path('mark.file.asset.url')(props) ||
            path('mark.fileDownload.file.asset.url')(props)
          }
        >
          {props.children}
        </Link>
      );
    },
    fileLinkNew: props => {
      return (
        <Link
          className="link-block"
          style={{
            color: textColor(props),
          }}
          external
          to={
            path('mark.fileDownload.file.asset.url')(props) ||
            path('mark.file.asset.url')(props)
          }
        >
          {props.children}
        </Link>
      );
    },
    internalLink: props => {
      return (
        <PageBlockLink
          className="link-block"
          style={{
            color: textColor(props) || colors.primary,
          }}
          data={props.mark}
        >
          {props.children}
        </PageBlockLink>
      );
    },
  },
  list: ({ children, type }) => {
    return (
      <List
        as={type === 'number' ? 'ol' : 'ul'}
        useNumbers={type === 'number'}
        className="list-block"
      >
        {children}
      </List>
    );
  },
  listItem: ({ children, node }) => {
    return (
      <ListItem className="list-item-block">
        <TextBlock className="text-block" variant="medium">
          {children}
        </TextBlock>
      </ListItem>
    );
  },
  types: {
    undefined: props => {
      // try extracting pure strings to text element
      if (!props.node) {
        return props.children;
      }

      const content = Object.values(props.node).join('');
      return (
        <TextBlock className="text-block" fontSize={props.node.style}>
          {content}
        </TextBlock>
      );
    },
    image: props => {
      return (
        <RichTextImage
          className="image-block"
          inPage={true}
          data={props.node}
        />
      );
    },
    videoComponent: props => {
      return <EmbedVideo url={props.node.url} />;
    },
    imageComponent: props => {
      return (
        <RichTextImage
          className="image-block"
          inPage={true}
          data={props.node}
        />
      );
    },
    dividerComponent: props => {
      return <RichTextDivider className="divider-block" data={props.node} />;
    },
    customTable: props => {
      return <Table className="table-block" data={props.node} />;
    },
    tabsComponent: props => {
      return <Tabs className="tabs-block" data={props.node} />;
    },
    block: props => {
      const { style } = props.node;
      if (/^\d+px$/.test(style)) {
        return (
          <TextBlock
            variant="medium"
            className="text-block"
            inline={props._type === 'span'}
            fontSize={style}
          >
            {props.children}
          </TextBlock>
        );
      }

      if (/^h\d+$/.test(style)) {
        return (
          <Heading
            mb={30}
            textAlign="left"
            data={{
              level: style,
              text: props.children,
            }}
            className="heading-block"
          />
        );
      }

      if (style === 'blockquote') {
        return (
          <PullQuoteBase
            my={{
              xs: 50,
              tabletP: 60,
              lg: 60,
            }}
            className="blockquote-block"
          >
            {props.children}
          </PullQuoteBase>
        );
      }

      if (isEmptyBlock(props)) {
        return <br />;
      }

      return (
        <TextBlock variant="medium" className="text-block">
          {BlockContent.defaultSerializers.types.block(props)}
        </TextBlock>
      );
    },
  },
};

const richTextContentCss = props => css`
  > .blockquote-block:first-child {
    margin-top: 0;
  }
  > .blockquote-block:last-child {
    margin-bottom: 0;
  }

  > .image-block:first-child {
    margin-top: 0;
  }
  > .image-block:last-child {
    margin-bottom: 0;
  }

  > .divider-block:first-child {
    margin-top: 0;
  }
  > .divider-block:last-child {
    margin-bottom: 0;
  }

  > .text:first-child {
    margin-top: 0;
  }
  > .text-block:last-child {
    margin-bottom: 0;
  }
  ${props.forceColor
    ? `
        &&& {
            .text-block,
            .heading-block,
            .blockquote-block,
            .superscript-block,
            .list-item-block,
            .link-block {
              color: ${path(props.forceColor)(props.theme.colors)};
            }
          }
  `
    : ''}
  ${props.forceFontSize
    ? `
        &&& {
            .text-block,
            .heading-block,
            .blockquote-block,
            .superscript-block,
            .list-item-block,
            .link-block {
              font-size: ${props.theme.fontSizes[props.forceFontSize]}px;
            }
          }
  `
    : ''}
`;

const StyledMultiBlockContent = styled(Box)`
  ${richTextContentCss}
`;

const StyledSingleBlockContent = styled(Box)`
  ${richTextContentCss}
`;

const SanityRichTextContent = ({
  content = [],
  interpolate,
  forceColor,
  renderRawText,
  customSerializers,
  ...props
}) => {
  const finalContent = useMemo(() => {
    return interpolate ? interpolateTextBlock(content, interpolate) : content;
  }, [content, interpolate]);

  if (renderRawText) {
    return renderRawText(getRawText(finalContent));
  }
  const blockContent = (
    <BlockContent
      blocks={finalContent}
      serializers={{ ...serializers, ...(customSerializers || {}) }}
    />
  );

  return content.length > 1 ? (
    <StyledMultiBlockContent forceColor={forceColor} {...props}>
      {blockContent}
    </StyledMultiBlockContent>
  ) : (
    <StyledSingleBlockContent forceColor={forceColor} {...props}>
      {blockContent}
    </StyledSingleBlockContent>
  );
};

export default SanityRichTextContent;
